import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const Materiel = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO title="Bateaux et matériel" />
      <BackgroundImage
        Tag="section"
        fluid={data.samatheo.childImageSharp.fluid}
        style={{ minHeight: "300px", height: "50vh" }}
      >
        <Container className="py-5">
          <h1>Bateaux et matériel</h1>
          <p>
            Afin de vous garantir un maximum de sécurité et de plaisir, nous
            vous proposons du matériel de qualité.
          </p>
        </Container>
      </BackgroundImage>
      <Container fluid="md" className="py-5">
        <Row>
          <Col>
            <p>Voici nos bateaux : </p>
            <ul>
              <li>
                Samatheo 2 : ce navire d’une longueur de 10,8 m pour 3,3 de large est motorisé par deux moteurs Honda de 250 cv 4 temps. Il a été spécialement construit sur mesure par le chantier naval Lacaze pour la pêche sportive du thon. Il possède une passerelle avec couchettes et WC ainsi que de nombreux rangements, un vivier et une glacière.
                <br />
                Du coté électronique, il est entièrement équipé FURUNO : 2 NAVNET TZ Touch 2, un ordinateur avec Maxsea, radar, deux pilotes automatiques FURUNO, INMARSAT, ...
              </li>
              <li>
                Vedette de type pêche sportive (construction sur mesure au chantier Navalu) d’une
                longueur de 7,8 mètres pour 2,8 de large. Ce navire est motorisé
                par un moteur Yamaha de 250 cv 4 temps / hors bord. Il possède
                un vivier de 150 litres et de nombreux coffres de rangement, …{" "}
                <br />
                Équipé d’électronique de dernière génération ( GPS Navnet TzTouch 3 de chez FURUNO) ainsi qu’un moteur
                électrique montage avant Minn Kota. La capacité d’accueil est de
                5 personnes maximum.
              </li>
              <div className="pb-4">
                <Img
                  className="my-3"
                  fluid={data.boats.childImageSharp.fluid}
                  alt="Christophe Barriola guide pêche capreton"
                />
              </div>
              <br />

            </ul>
            <p>
              Pour votre sécurité, les bateaux possède tout le matériels de
              sécurité nécessaire et j’ai choisi d’équiper chaque stagiaire
              pêcheur d’un gilet de sauvetage automatique 150 N. Je suis fier de
              vous faire pêcher avec du matériel de qualité pour vous garantir
              un maximum de plaisir.
            </p>
            <p>
              Nos prestations comprennent le prêt de matériel de pêche de
              qualité adapté en fonction de chaque technique. Les leurres et /
              ou appâts sont également fournis.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const data = graphql`
  query {
    boats: file(relativePath: { eq: "equipe/petit.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    samatheo: file(relativePath: { eq: "equipe/samatheo.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    titeuf: file(relativePath: { eq: "equipe/titeuf.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Materiel
